import React, { Component } from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import posed from 'react-pose'
import ScrollManager from '../helper/scrollManager.jsx'
import { connect } from 'react-redux'
import Circle from '../components/graphic/circle.jsx'
import { easeFunction } from '../helper/variables.jsx'
import ServiceBlock from '../components/homepageBlocks/serviceBlock.jsx'
import InsightsBlock from '../components/homepageBlocks/insightsBlock.jsx'
import Footer from '../components/common/footer.jsx'
import ServiceIndicator from '../components/homepageBlocks/serviceIndicator.jsx'
import IntroBlock from '../components/homepageBlocks/introBlock.jsx'
import WorksBlock from '../components/homepageBlocks/worksBlock.jsx'
import HeroBlock from '../components/homepageBlocks/heroBlock.jsx'
import Layout from '../layout/layout.jsx'

import './index.css'
import { BrowserView, isBrowser } from 'react-device-detect'
import ServiceFloat from '../components/homepageBlocks/serviceFloat.jsx'
import VisibilityWrapper from '../helper/visibilityWrapper.jsx'
import ReactGA from 'react-ga'

const PageTransition = posed.div({
  enter: {
    opacity: 1,
    transition: {
      ease: easeFunction(),
      duration: 500,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      ease: easeFunction(),
      duration: 500,
    },
  },
})

class IndexPage extends Component {
  state = {
    mainNode: null,
    scrollValue: 0,
    momentumScrollValue: 0,
    triggerPointWorks: 0,
    triggerPointIntro: 0,
    triggerPointService: 0,
    triggerPointInsights: 0,
    triggerPointFooter: 0,
    servicePage: 0,
    servicePosition: 0,
    strategyPoint: 0,
    websitePoint: 0,
    creativePoint: 0,
    windowheight: 0,
  }

  constructor(props) {
    super(props)

    this.mainNode = React.createRef()
    this.sectionWorks = React.createRef()
    this.sectionIntro = React.createRef()
    this.sectionService = React.createRef()
    this.sectionInsights = React.createRef()
    this.sectionFooter = React.createRef()
  }

  componentDidMount() {
    ReactGA.initialize('UA-48349543-1')

    if (typeof window !== 'undefined')
      ReactGA.pageview(window.location.pathname + window.location.search)

    this.assignTriggerPoints()

    if (typeof window !== 'undefined')
      window.addEventListener('resize', this.assignTriggerPoints)

    this.setState({
      mainNode: this.mainNode.current,
    })

    if (this.props.showHomepageLoading) {
      this.initialLoadingAnimation()
    } else {
      this.props.hideSymbol()
      this.props.backgroundBlack()
    }
  }

  componentWillUnmount() {
    if (typeof window !== 'undefined')
      window.removeEventListener('resize', this.assignTriggerPoints)

    this.props.backgroundWhite()
  }

  assignTriggerPoints = event => {
    const windowHeight = typeof window !== 'undefined' && window.innerHeight
    const { momentumScrollValue } = this.state

    this.setState({
      windowHeight,
      triggerPointWorks:
        momentumScrollValue +
        this.sectionWorks.current.getBoundingClientRect().top,
      triggerPointIntro:
        momentumScrollValue +
        this.sectionIntro.current.getBoundingClientRect().top,
      triggerPointService:
        momentumScrollValue +
        this.sectionService.current.getBoundingClientRect().top,
      triggerPointInsights:
        momentumScrollValue +
        this.sectionInsights.current.getBoundingClientRect().top -
        (isBrowser ? windowHeight : 0),
      triggerPointFooter:
        momentumScrollValue +
        this.sectionFooter.current.getBoundingClientRect().top,
    })
  }

  initialLoadingAnimation = () => {
    setTimeout(() => {
      this.props.noHomepageLoading()
      this.props.backgroundBlack()
    }, 1500)
  }

  updateMomentumScrollValue = value => {
    this.setState({
      momentumScrollValue: value,
    })

    if (value > this.state.triggerPointFooter - (isBrowser ? 75 : 55)) {
      this.props.backgroundBlack()
    } else if (value > this.state.triggerPointInsights) {
      this.props.backgroundWhite()
      this.setState({
        servicePage: 0,
      })
    } else if (value > this.state.triggerPointService) {
      this.setState({
        servicePage: 1,
      })

      if (value > this.state.strategyPoint) {
        this.setState({
          servicePosition: 3,
        })
      } else if (value > this.state.creativePoint) {
        this.setState({
          servicePosition: 2,
        })
      } else if (value > this.state.websitePoint) {
        this.setState({
          servicePosition: 1,
        })
      }
    } else if (value > this.state.triggerPointService - (isBrowser ? 75 : 55)) {
      this.props.backgroundWhite()
    } else if (value > this.state.triggerPointIntro - (isBrowser ? 75 : 55)) {
      this.props.backgroundRed()
      this.setState({
        servicePage: 0,
      })
    } else if (value > this.state.triggerPointIntro - (isBrowser ? 200 : 100)) {
      this.props.backgroundWhite()
      this.setState({
        servicePage: 0,
      })
    } else if (value > this.state.triggerPointWorks - (isBrowser ? 75 : 55)) {
      this.props.backgroundWhite()
    } else if (value > this.state.triggerPointWorks - (isBrowser ? 200 : 100)) {
      this.setState({
        servicePage: 0,
      })
    } else {
      this.props.backgroundBlack()
      this.setState({
        servicePage: 0,
      })
    }
  }

  updateScrollValue = value => {
    this.setState({
      scrollValue: value,
    })
  }

  updateServicePoints = points => {
    this.setState(points)
  }

  render() {
    const homepage = this.props.data.contentfulHomepage
    const { edges: works } = this.props.data.allContentfulWork
    const { edges: insights } = this.props.data.allContentfulInsight
    const { showHomepageLoading, theme } = this.props
    const { momentumScrollValue, servicePage, servicePosition } = this.state

    return (
      <Layout ref={this.mainNode}>
        <Helmet>
          <html lang="en" />
          <title>{homepage.metaData.metaTitle}</title>
          <meta name="description" content={homepage.metaData.description} />
          <meta name="keywords" content={homepage.metaData.keywords} />
          <meta property="og:title" content={homepage.metaData.metaTitle} />
          <meta
            property="og:description"
            content={homepage.metaData.description}
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:image"
            content={
              homepage.metaData.ogImage
                ? `https:${homepage.metaData.ogImage.file.url}`
                : 'https://www.baunfire.com/ogimage.jpg'
            }
          />
          <meta property="og:url" content="https://www.baunfire.com" />
          <link rel="canonical" href="https://www.baunfire.com" />
        </Helmet>
        <PageTransition>
          <ScrollManager
            page={this.state.mainNode}
            scrollValue={this.state.scrollValue}
            updateScrollValue={this.updateScrollValue}
            updateMomentumScrollValue={this.updateMomentumScrollValue}
          >
            <div className="page-index">
              <div
                className={`background-color-area theme-black ${(theme ===
                  'black' ||
                  !showHomepageLoading) &&
                  'active'} top-of-page`}
              >
                <Circle
                  showHomepageLoading={showHomepageLoading}
                  theme={theme}
                />
                <div className="content-container hero-wrapper">
                  <section className="section-hero section-block">
                    <HeroBlock
                      theme={theme}
                      data={homepage}
                      momentumScrollValue={momentumScrollValue}
                      showHomepageLoading={showHomepageLoading}
                    />
                  </section>
                </div>
              </div>
              <div className="content-container">
                <section
                  className="section-works section-block"
                  ref={this.sectionWorks}
                >
                  <WorksBlock
                    theme={theme}
                    data={homepage}
                    works={works}
                    momentumScrollValue={momentumScrollValue}
                  />
                </section>
              </div>
              <VisibilityWrapper partialVisibility={true}>
                {({ isVisible }) => {
                  return (
                    <div className={`area-intro ${isVisible && 'appear'}`}>
                      <div className="content-container">
                        <section
                          className="section-intro section-block"
                          ref={this.sectionIntro}
                        >
                          <IntroBlock data={homepage} />
                        </section>
                      </div>
                    </div>
                  )
                }}
              </VisibilityWrapper>
              <div className="content-container">
                <section
                  className="section-service section-block"
                  ref={this.sectionService}
                >
                  <ServiceBlock
                    data={homepage}
                    momentumScrollValue={momentumScrollValue}
                    updateServicePoints={this.updateServicePoints}
                  />
                </section>
              </div>
              <div className="content-container">
                <section
                  className="section-insights section-block"
                  ref={this.sectionInsights}
                >
                  <InsightsBlock
                    theme={theme}
                    data={homepage}
                    momentumScrollValue={momentumScrollValue}
                    insights={insights}
                  />
                </section>
              </div>
            </div>
            <section ref={this.sectionFooter}>
              <Footer />
            </section>
          </ScrollManager>
          <BrowserView>
            <section className="floating-service">
              <ServiceFloat
                data={homepage}
                momentumScrollValue={momentumScrollValue}
                updateServicePoints={this.updateServicePoints}
                servicePosition={servicePosition}
                onService={servicePage}
              />
            </section>
            <ServiceIndicator
              onService={servicePage}
              servicePosition={servicePosition}
            />
          </BrowserView>
        </PageTransition>
      </Layout>
    )
  }
}

export const query = graphql`
  query($title: String = "Homepage") {
    contentfulHomepage(title: { eq: $title }) {
      id
      metaData {
        title
        metaTitle
        description
        keywords
      }
      heroRibbonText
      heroHeadline
      heroDescription
      heroButtonText
      worksTitle
      worksDescription
      introRibbonText
      introText
      servicesTitle
      servicesDescription
      strategyImage {
        fixed(width: 625, quality: 90) {
          ...GatsbyContentfulFixed_withWebp_noBase64
        }
      }
      strategyDescription
      websiteImage {
        fixed(width: 625, quality: 90) {
          ...GatsbyContentfulFixed_withWebp_noBase64
        }
      }
      websiteDescription
      creativeImage {
        fixed(width: 625, quality: 90) {
          ...GatsbyContentfulFixed_withWebp_noBase64
        }
      }
      creativeDescription
      insightsTitle
      insightsDescription
    }
    allContentfulWork(limit: 4, sort: { fields: order, order: ASC }, filter: {
      live: {
        eq: true
      }
    }) {
      edges {
        node {
          id
          order
          slug
          project
          type
          thumbnail {
            file {
              url
              fileName
              contentType
            }
            description
            fluid(maxWidth: 560, quality: 90) {
              src
              srcSet
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          metaData {
            title
            metaTitle
            description
            keywords
          }
        }
      }
    }
    allContentfulInsight(limit: 4, sort: { fields: order, order: ASC }) {
      edges {
        node {
          id
          title
          order
          slug
          category
          keyImage {
            fluid(maxWidth: 560, quality: 90) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`

const mapStateToProps = state => {
  return {
    theme: state.backgroundColor,
    showHomepageLoading: state.showHomepageLoading,
    loaded: state.loaded,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    backgroundBlack: () => dispatch({ type: 'BACKGROUND_BLACK' }),
    backgroundWhite: () => dispatch({ type: 'BACKGROUND_WHITE' }),
    backgroundRed: () => dispatch({ type: 'BACKGROUND_RED' }),
    noHomepageLoading: () => dispatch({ type: 'NO_HOMEPAGE_LOADING' }),
    hideSymbol: () => dispatch({ type: 'SHOW_LOGO' }),
    flagLoad: () => dispatch({ type: 'LOADED' }),
  }
}

const ConnectedIndex = connect(
  mapStateToProps,
  mapDispatchToProps
)(IndexPage)

export default ConnectedIndex
