import React from 'react'
import Sparks from '../graphic/sparks'

import './introBlock.css'
import SplitText from 'react-pose-text'
import VisibilityWrapper from '../../helper/visibilityWrapper'
import { animations } from '../../helper/variables'

const IntroBlock = props => {
  const { data } = props

  const slideAnimation = animations('SLIDE')

  return (
    <VisibilityWrapper partialVisibility={true}>
      {({ isVisible }) => {
        return (
          <div className="intro">
            <Sparks type="3" className="spark3" />
            <div>
              <h2 className={`type-h11 color-white ribbon`}>
                <SplitText
                  initialPose="exit"
                  withParent={false}
                  pose={isVisible ? 'enter' : 'exit'}
                  charPoses={slideAnimation}
                >
                  {data.introRibbonText}
                </SplitText>
              </h2>
              <div className={`intro-context type-sub2 color-white main`}>
                {data.introText}
              </div>
            </div>
          </div>
        )
      }}
    </VisibilityWrapper>
  )
}

export default IntroBlock
